module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Digital Safety Research Institute","short_name":"DSRI","start_url":"/","icon":"src/images/ulresearchinst_logo_horz_blue_rgb-150x150.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"03cb6fa496598fdc675ace5d7ad0dbfe"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"dsri-clara","accessToken":"MC5aeUpHUWhFQUFDb0FOZW5h.au-_ve-_ve-_ve-_ve-_vRvvv71nUu-_vVonIXNh77-977-977-977-977-9RU7vv70WJO-_vW1XRe-_vUc"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
